import { useState, useEffect } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import './App.scss';
import MultiNumber from './MultiNumber'
import Logo from './Logo'

const CustomForm = ({ status, message, onValidated }) => {
  let email, name;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <div>
      <input
        ref={node => (email = node)}
        type="email"
        placeholder="Email address"
      />
      <br />
      <button onClick={submit}>
        Submit
      </button>
    </div>
  );
};


function App() {
  const [remaining, setRemaining] = useState(0)

  var _second = 1000;
  var _minute = _second * 60;
  var _hour = _minute * 60;
  var _day = _hour * 24;
  var _week = _day * 7;
  var timer;
  const end = new Date('01/01/2025')

  function pad(num, size) {
    num = num.toString();
    while (num.length < 2) num = "0" + num;
    return num;
}

  function calcRemaining() {
      var now = new Date();
      var distance = end - now;
      setRemaining(distance > 0 ? distance : 0)
  }

  var days = Math.floor(remaining / _day);
  var hours = Math.floor((remaining % _day) / _hour);
  var minutes = Math.floor((remaining % _hour) / _minute);
  var seconds = Math.floor((remaining % _minute) / _second);

  // let timer = null

  useEffect(() => {
    calcRemaining()
    timer = setInterval(() => calcRemaining(), 1000)
  }, [])

  const mcUrl = "//composite.us7.list-manage.com/subscribe/post?u=184dce95597b2ce050033d474&id=8716303cab";

  return (
    <div className="app">
      <div className="header grid">
        <div className="logo">
          <Logo />
          <span>omposite&nbsp;Radio</span>
        </div>
      </div>
      <div className="count grid">
        <MultiNumber num={pad(days)} unit="Days"/>
        <MultiNumber num={pad(hours)} unit="Hours"/>
        <MultiNumber num={pad(minutes)} unit="Minutes"/>
        <MultiNumber num={pad(seconds)} unit="Seconds" />
      </div>
      <div className="grid text">
      <div className="intro">
      <p>
        Imagined as a contemporary theatre of sonic art, performance, experimental music, talks, criticism, audio workshops, and reading groups, Composite Radio is a platform supporting interdisciplinary, critical, and speculative work that attunes (to)/detunes (from) the currents of our time.
      </p>
      <p>
      Broadcasting from the Brunswick Mechanics Institute, which originally opened in 1868 as a lending library for the local community and is now home to Next Wave a multi-arts organisation nurturing early-career artists.
      </p>
      <p>
      Composite Radio is produced by Composite, an Artist-Run organisation dedicated to supporting artists’ moving image. Interested in presenting a show on Composite Radio? Get in touch on <a href="mailto:hello@composite.org.au">hello@composite.org.au</a>.
      </p>
      </div>
      <div className="sign-up">
        <p>Listen Live</p>
        <div>
        <iframe src="//www.radiojar.com/widget/radio/ctpez559rp3vv/player/" frameborder="0" width="320" height="134" scrolling="no"></iframe>
        </div>
        <p>View our evolving Broadcast Program <a href='https://sites.google.com/composite.org.au/compositeradioguide' target='_blank'>here!</a></p>
        {/* <p>Sign up to our newsletter for updates:</p>
        <div>
          <MailchimpSubscribe
            url={mcUrl}
            render={({ subscribe, status, message }) => (
              <div className="form-wrap">
                <CustomForm onValidated={formData => subscribe(formData)} />
                <div className="response">
                {status === "sending" && <div>sending...</div>}
                {status === "error" && !(message.indexOf('is already subscribed') > 0) &&  <div dangerouslySetInnerHTML={{__html: message}}/>}
                {(status === "success" || message && message.indexOf('is already subscribed') > 0) && <div>done.</div>}
                </div>
              </div>
            )}
          />
        </div> */}
      </div>
      </div>
    </div>
  );
}

export default App;
